import React from "react";
import { useSelector } from "react-redux";
import { ObservationsEditable as OET } from "./ReportPages/ReportObservations";

export default function ObservationInfo({ index, roomIndex }) {
  const observation = useSelector(
    (state) => state.report.observations.data[roomIndex].observations[index]
  );
  const roomName = useSelector(
    (state) => state.report.observations.data[roomIndex].room_name
  );

  const observationLength = useSelector(
    (state) => state.report.observations.data[roomIndex].observations.length
  );

  return (
    <div className="observation-info">
      <div>
        <div className="sub-grid">
          <p>Room:</p>
          <p>
            <strong>
              <OET reduxKey="room_name" ml="50" ri={roomIndex} editRoomName>
                {roomName}
              </OET>
            </strong>
          </p>
        </div>
      </div>
      <div>
        <div className="sub-grid">
          <p>
            ID:{" "}
            <strong>#{observation?.code?.toUpperCase() ?? ""}</strong>
          </p>
        </div>
      </div>
      <div>
        <div className="sub-grid">
          <p>Location:</p>
          <p>
            <strong>
              <OET reduxKey="location" ml="50" oi={index} ri={roomIndex}>
                {observation?.location}
              </OET>
            </strong>
          </p>
        </div>
      </div>
      <div>
        <p style={{ textAlign: "right" }}>
          <strong>{`${index + 1} of ${observationLength}`}</strong>
        </p>
      </div>
    </div>
  );
}

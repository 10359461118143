import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import editModalReducer from "./edit-modal.reducer";
import headerReducer from "./header.reducer";
import reportReducer from "./report.reducer";
import sidenavReducer from "./sidenav.reducer";
import userReducer from "./user.reducer";
import documentReducer from "./document.reducer";
import subscriptionReducer from "./subscription.reducer";

const Reducer = combineReducers({
  user: userReducer,
  header: headerReducer,
  sidenav: sidenavReducer,
  app: appReducer,
  report: reportReducer,
  editModal: editModalReducer,
  docreducer: documentReducer,
  subscriptionReducer: subscriptionReducer,
});

export default Reducer;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import {
  faRedoAlt,
  faTimes,
  faUndoAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  SET_REPORT,
  SET_REPORT_OBSERVATION_PHOTO,
  SET_REPORT_OBSERVATION_TEST_PHOTO,
  SHOW_MODAL_CONFIRM,
} from "../../../../store/actions";
import { fileChangedHandler } from "../../../shared/helpers";
export const ROTATE = "$rotate$"; //do not change

export default function ImageEditor(props) {
  const {
    src,
    reduxKey,
    rotation,
    imgRotationKey,
    disableRotate = false,
  } = props;
  const [link] = src.split(ROTATE);
  const [scale, setScale] = useState(1);
  const [rotatedScale, setRotatedScale] = useState(0);
  const [localAngle, setLocalAngle] = useState(rotation ?? 0);
  const [fileError, setFileError] = useState("");

  const imgRef = useRef(null);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const ucp = (path, text) => {
    dispatch({
      type: SET_REPORT,
      payload: { path, data: text },
    });
  };

  const setRotation = (angle) => {
    setLocalAngle(angle);
    dispatch({
      type: SET_REPORT,
      payload: { path: imgRotationKey, data: angle },
    });
  };

  useLayoutEffect(() => {
    setLocalAngle(rotation);
  }, [rotation]);

  useLayoutEffect(() => {
    if (
      rotation === 90 ||
      rotation === -90 ||
      rotation === 270 ||
      rotation === -270
    ) {
      setScale(rotatedScale);
    } else {
      setScale(1);
    }
  }, [rotation, rotatedScale]);

  useLayoutEffect(() => {
    if (imgRef.current) {
      setRotatedScale(imgRef.current.offsetHeight / imgRef.current.offsetWidth);
    }
  }, [imgRef]);

  return (
    <div className="d-flex justify-content-center report-img">
      <div className="image-editor">
        {src && !disableRotate && (
          <div className="rotate-btns">
            <FontAwesomeIcon
              icon={faUndoAlt}
              onClick={() => setRotation(rotation === -270 ? 0 : rotation - 90)}
            />
            <span className="vl"></span>
            <FontAwesomeIcon
              icon={faRedoAlt}
              onClick={() => setRotation(rotation === 270 ? 0 : rotation + 90)}
            />
          </div>
        )}
        {link ? (
          <img
            ref={imgRef}
            {...props}
            src={link}
            style={{
              transform: `rotate(${localAngle}deg) scale(${scale})`,
              transition: "all 0.4s ease 0s",
            }}
          />
        ) : (
          <div className="d-flex flex-column align-items-center">
            <FontAwesomeIcon
              icon={faUpload}
              size="2x"
              color="gray"
              className="cursor-pointer"
              onClick={() => {
                inputRef.current?.click();
              }}
            />
            {fileError && <div>{fileError}</div>}
            <input
              ref={inputRef}
              type="file"
              accept="image/jpeg,image/png"
              onChange={(e) =>
                fileChangedHandler(
                  e,
                  setFileError,
                  (url) => ucp(reduxKey, url),
                  setRotation
                )
              }
              style={{ display: "none" }}
            />
          </div>
        )}
        {link && (
          <div className="image-editor-tools">
            <FontAwesomeIcon
              icon={faTimes}
              color="#b44a4a"
              onClick={() => {
                dispatch({
                  type: SHOW_MODAL_CONFIRM,
                  payload: {
                    showConfirm: true,
                    onDelete: () => ucp(reduxKey, ""),
                  },
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function ObservationPhotoEditor(props) {
  const { src, oi, pi, ri, ti, ii, rotation } = props;
  const [link] = src.split(ROTATE);
  const [scale, setScale] = useState(1);
  const [rotatedScale, setRotatedScale] = useState(0);
  const [fileError, setFileError] = useState("");
  const [localAngle, setLocalAngle] = useState(rotation ?? 0);

  const imgRef = useRef(null);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const ucp = (text) => {
    if (pi !== undefined) {
      dispatch({
        type: SET_REPORT_OBSERVATION_PHOTO,
        payload: {
          path: "url",
          data: text,
          observationIndex: oi,
          photoIndex: pi,
          roomIndex: ri,
        },
      });
      dispatch({
        type: SET_REPORT_OBSERVATION_PHOTO,
        payload: {
          path: "rotation",
          data: rotation,
          observationIndex: oi,
          photoIndex: pi,
          roomIndex: ri,
        },
      });
    } else if (ti !== undefined) {
      dispatch({
        type: SET_REPORT_OBSERVATION_TEST_PHOTO,
        payload: {
          path: "url",
          data: text,
          observationIndex: oi,
          testIndex: ti,
          roomIndex: ri,
          imageIndex: ii,
        },
      });
      dispatch({
        type: SET_REPORT_OBSERVATION_TEST_PHOTO,
        payload: {
          path: "rotation",
          data: rotation,
          observationIndex: oi,
          testIndex: ti,
          roomIndex: ri,
          imageIndex: ii,
        },
      });
    }
  };

  const setRotation = (angle) => {
    if (pi !== undefined) {
      dispatch({
        type: SET_REPORT_OBSERVATION_PHOTO,
        payload: {
          path: "rotation",
          data: angle,
          observationIndex: oi,
          photoIndex: pi,
          roomIndex: ri,
        },
      });
    } else if (ti !== undefined) {
      dispatch({
        type: SET_REPORT_OBSERVATION_TEST_PHOTO,
        payload: {
          path: "rotation",
          data: angle,
          observationIndex: oi,
          testIndex: ti,
          roomIndex: ri,
          imageIndex: ii,
        },
      });
    }
    setLocalAngle(angle);
  };

  useLayoutEffect(() => {
    if (
      rotation === 90 ||
      rotation === -90 ||
      rotation === 270 ||
      rotation === -270
    ) {
      setScale(rotatedScale);
    } else {
      setScale(1);
    }
  }, [rotation, rotatedScale]);

  useLayoutEffect(() => {
    setLocalAngle(rotation);
  }, [rotation]);

  useLayoutEffect(() => {
    if (imgRef.current) {
      const height = imgRef.current.offsetHeight;
      const width = imgRef.current.offsetWidth;
      if (height < width) {
        setRotatedScale(height / width);
      } else {
        setRotatedScale(width / height);
      }
    }
  }, [imgRef, link]);

  return (
    <div className="d-flex report-img" style={{ padding: "0 0.5cm" }}>
      <div className="image-editor">
        {link && (
          <div className="obs-photo-rotate-btns">
            <FontAwesomeIcon
              icon={faUndoAlt}
              onClick={() => setRotation(rotation === -270 ? 0 : rotation - 90)}
            />
            <span className="vl"></span>
            <FontAwesomeIcon
              icon={faRedoAlt}
              onClick={() => setRotation(rotation === 270 ? 0 : rotation + 90)}
            />
          </div>
        )}
        {link ? (
          <img
            ref={imgRef}
            {...props}
            src={link}
            style={{
              transform: `rotate(${localAngle}deg) scale(${scale})`,
              transition: "all 0.4s ease 0s",
            }}
          />
        ) : (
          <div className="upload-new-img">
            <FontAwesomeIcon
              icon={faUpload}
              size="2x"
              color="gray"
              className="cursor-pointer"
              onClick={() => {
                inputRef.current?.click();
              }}
            />
            {fileError && <div>{fileError}</div>}
            <input
              ref={inputRef}
              type="file"
              accept="image/jpeg,image/png"
              onChange={(e) =>
                fileChangedHandler(e, setFileError, ucp, setRotation)
              }
              style={{ display: "none" }}
            />
          </div>
        )}
        {link && (
          <div className="image-editor-tools">
            <FontAwesomeIcon
              icon={faTimes}
              color="#b44a4a"
              onClick={() => {
                dispatch({
                  type: SHOW_MODAL_CONFIRM,
                  payload: {
                    showConfirm: true,
                    onDelete: () => ucp(""),
                  },
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import {
  getReportData,
  getReportPdf,
  refreshReport,
  saveReport,
} from "../../../services/IReportService";
import { SET_REPORT_DATA } from "../../../store/actions";
import Spinner from "../../components/Spinner";
import {
  formatReportData,
  getErrorMsg,
  getReportDataForApi,
  getReportKeys,
  isEmptyObject,
  setHeader,
  showToast,
} from "../../shared/helpers";
import { regexes } from "../../shared/regexes";
import AddPageButton from "./Components/AddPageButton";
import DocumentNavigator from "./Components/DocumentNavigator";
import EditTextModal from "./Components/EditTextModal";
import Background from "./Components/ReportPages/Background";
import ExtraPage from "./Components/ReportPages/ExtraPage";
import Intro from "./Components/ReportPages/Intro";
import ObservationComments from "./Components/ReportPages/ObservationsPages/ObservationComments";
import ObservationPhotos from "./Components/ReportPages/ObservationsPages/ObservationPhotos";
import ObservationTests from "./Components/ReportPages/ObservationsPages/ObservationTests";
import Protocol from "./Components/ReportPages/Protocol";
import ReportInspection from "./Components/ReportPages/ReportInspection";
import TemplatePage from "./Components/ReportPages/TemplatePage";
import TOC from "./Components/ReportPages/TOC";
import { HIDE_MODAL_CONFIRM } from "../../../store/actions";
import "./style.css";
import ReportTerms from "./Components/ReportPages/ReportTerms";

const ConfirmationModal = React.lazy(() =>
  import("../Settings/Users/ConfirmationModal")
);

const reportPages = {
  cover_page: Intro,
  toc: TOC,
  background: Background,
  inspection: ReportInspection,
  observations: "observations",
  specific_decontamination: (props) => (
    <TemplatePage templateKey={"specific_decontamination"} {...props} />
  ),
  generel_decontamination: (props) => (
    <TemplatePage templateKey={"generel_decontamination"} {...props} />
  ),
  sampling: (props) => <TemplatePage templateKey={"sampling"} {...props} />,
  laboratory: (props) => <TemplatePage templateKey={"laboratory"} {...props} />,
  interpreting: (props) => (
    <TemplatePage templateKey={"interpreting"} {...props} />
  ),
  health_effects: (props) => (
    <TemplatePage templateKey={"health_effects"} {...props} />
  ),
  reference: (props) => <TemplatePage templateKey={"reference"} {...props} />,
  limitation: (props) => <TemplatePage templateKey={"limitation"} {...props} />,
};

export default function InvestigationReport() {
  const [scrollToRow, setScrollToRow] = useState(0);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { insp_id } = useParams();
  const editModal = useSelector((state) => state.editModal);
  const observations = useSelector((state) => state.report?.observations?.data);
  const report = useSelector((state) => state.report);
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  console.log(report, "==========")
  const [pages, setPages] = useState([]);
  const list = useRef(null);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 500,
    })
  );
  const setReportData = (res) => {
    const content = res?.data?.cover_page?.report?.terms?.content;
    if (content) {
      res.data.cover_page.report.terms.content = content.replace(
        regexes.styleAttribute,
        ""
      );
    }

    formatReportData(res.data);
    console.log(res.data);
    setHeader(dispatch, {
      title:
        "Inspection Report - " + res?.data?.cover_page?.property?.address ?? "",
    });
    dispatch({ type: SET_REPORT_DATA, payload: res.data });
  };

  useEffect(() => {
    setIsLoading(true);
    getReportData(insp_id)
      .then((res) => {
        setReportData(res);
      })
      .catch((err) => {
        console.log(err);
        showToast("Unable to load report data");
      })
      .finally(() => setIsLoading(false));
    console.log("useefect");
  }, []);

  useEffect(() => {
    if (!isEmptyObject(report)) {
      console.time("Report loop");
      const tempPages = [];
      const reportKeys = getReportKeys(report);
      reportKeys.forEach((key) => {
        if (key === "observations") {
          observations.forEach((o, i) => {
            o.observations.forEach((obs, j) => {
              if (obs?.comments?.length > 0) {
                tempPages.push((props) => (
                  <ObservationComments
                    insp_id={insp_id}
                    sethandleSaveState={sethandleSaveState}
                    index={j}
                    roomIndex={i}
                    comments={obs.comments}
                    {...props}
                  />
                ));
              }
              if (obs?.images?.length > 0) {
                tempPages.push((props) => (
                  <ObservationPhotos
                    index={j}
                    roomIndex={i}
                    photos={obs.images}
                    {...props}
                  />
                ));
              }
              if (obs?.tests?.length > 0) {
                tempPages.push((props) => (
                  <ObservationTests
                    index={j}
                    roomIndex={i}
                    tests={obs.tests}
                    {...props}
                  />
                ));
              }
            });

            tempPages.push((props) => (
              <Protocol roomIndex={i} protocols={o.protocol_text} {...props} />
            ));
          });
        } else {
          tempPages.push(reportPages[key]);
        }
      });
      report?.extra_pages.forEach((extra, i) => {
        tempPages.push((props) => (
          <ExtraPage {...props} data={extra} index={i} />
        ));
      });
      tempPages.push((props) => <AddPageButton {...props} />);
      setPages(tempPages);
      console.timeEnd("Report loop");
    }
  }, [report]);

  useEffect(() => {
    list?.current?.scrollToRow(scrollToRow);
    const id = setTimeout(() => {
      list?.current?.scrollToRow(scrollToRow);
    }, 250);

    return () => clearTimeout(id);
  }, [scrollToRow]);

  useEffect(() => {
    console.log(list.current?.Grid?._scrollingContainer);
  }, [list]);

  useEffect(() => {
    console.log(cache?.current?._rowHeightCache);
  }, [cache]);

  const handlePublish = () => {
    setIsPdfLoading(true);
    console.log(getReportDataForApi(report));
    getReportPdf(getReportDataForApi(report), insp_id)
      .then(() => {
        showToast(
          "Inspection Report will be available in Documents listing shortly.",
          true
        );
      })
      .catch((err) => {
        console.log(err);
        showToast("Error while generating pdf");
      })
      .finally(() => setIsPdfLoading(false));
  };

  const [handleSaveState, sethandleSaveState] = useState(false);
  useEffect(() => {
    if (handleSaveState) {
      handleSave();
      sethandleSaveState(false);
    }
  }, [report]);
  // console.log(handleSaveState, "-------------------")

  const handleSave = () => {
    setIsPdfLoading(true);
    saveReport(insp_id, { data: JSON.stringify(report) })
      .then(() => {
        showToast("Report saved successfully", true);
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Something went wrong");
      })
      .finally(() => setIsPdfLoading(false));
  };

  const handleRefresh = (setDisableBtns) => {
    setDisableBtns(true);
    setIsPdfLoading(true);
    refreshReport(insp_id)
      .then((res) => {
        showToast("Report refreshed successfully", true);
        setReportData(res);
        setShowRefreshModal(false);
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Something went wrong");
      })
      .finally(() => {
        setIsPdfLoading(false);
        setDisableBtns(false);
      });
  };

  return (
    <div id="report" className="d-flex flex-row">
      <Spinner reactIf={isLoading} />
      {!isLoading && !isEmptyObject(report) ? (
        <>
          <div className="ls py-2 pr-0">
            <DocumentNavigator setScrollToRow={setScrollToRow} />
          </div>
          <div id="reportScroll" className="rs">
            <div className="report-action-container shadow">
              <Button
                className="report-save-btn dis-loading"
                onClick={handleSave}
                disabled={isPdfLoading}
              >
                Save
              </Button>
              <Button
                className="report-save-btn dis-loading"
                onClick={() => setShowRefreshModal(true)}
                disabled={isPdfLoading}
              >
                Refresh
              </Button>
              {/* <DownloadPDF report={report} /> */}
              <Button
                className="dis-loading"
                onClick={handlePublish}
                disabled={isPdfLoading}
              >
                Publish
              </Button>
            </div>
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={cache.current.rowHeight}
                  deferredMeasurementCache={cache.current}
                  rowCount={pages.length}
                  ref={list}
                  scrollToAlignment="start"
                  rowRenderer={({ key, index, style, parent }) => {
                    const Page = pages[index];
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <Page style={style} id={`page${index}`} />
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
          <EditTextModal
            {...editModal}
            sethandleSaveState={sethandleSaveState}
          />
        </>
      ) : null}
      <Suspense fallback={null}>
        <ConfirmationModal
          show={showRefreshModal}
          onHide={() => setShowRefreshModal(false)}
          msg={
            "Refreshing this document will erase ALL edited information and create a new draft. Are you sure you want to refresh this document?"
          }
          onDelete={handleRefresh}
          yesBtnText="Refresh"
          noBtnText="Cancel"
        />
        <ConfirmationModal
          show={editModal.showConfirm}
          onHide={() =>
            dispatch({
              type: HIDE_MODAL_CONFIRM,
            })
          }
          msg={"Are you sure you want to delete this photo?"}
          onDelete={editModal.onDelete}
          yesBtnText="Delete"
          noBtnText="Cancel"
          hideOnPress
        />
      </Suspense>
    </div>
  );
}

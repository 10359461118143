import React from "react";
import TopRightTitle from "../TopRightTitle";
import ET from "../EditableText";
import DocumentView from "../DocumentView";
import { useSelector } from "react-redux";

export default function TOC({ style }) {
  const title = useSelector((state) => state.report.toc.title);
  const content = useSelector((state) => state.report.toc.content);
  const observationRooms = useSelector(
    (state) => state.report.observations.data
  );

  return (
    <DocumentView style={style}>
      <div className="document-head">
        <header>
          <div className="grid-pg1">
            <div>
              <h3>
                <strong>
                  <ET reduxKey={"toc.title"} ml="50">
                    {title}
                  </ET>
                </strong>
              </h3>
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>

      <div className="doc-body">
        <ul className="sections-list">
          {content.map((section, i) =>
            section.title !== "Intro" ? (
              <li key={`section-${i}`}>
                <li className="section-list-title">{section.title}</li>
                <ul className="sub-sections-list">
                  {section.title === "Observations"
                    ? observationRooms.map((r, k) => (
                        <li
                          className="sub-section-list-title"
                          key={`subsection-${k}`}
                        >
                          <div>{r.room_name}</div>
                          <div></div>
                        </li>
                      ))
                    : section.sub_content.map((content, j) => (
                        <li
                          className="sub-section-list-title"
                          key={`subsection-${j}`}
                        >
                          <div>{content.label}</div>
                          <div></div>
                        </li>
                      ))}
                </ul>
              </li>
            ) : null
          )}
        </ul>
      </div>

      
    </DocumentView>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import DocumentView from "../DocumentView";
import ET from "../EditableText";
import TopRightTitle from "../TopRightTitle";
import "./style.css";

export default function ReportInspection({ style }) {
  const inspection = useSelector((state) => state.report.inspection);

  return (
    <DocumentView style={style}>
      <div className="document-head">
        <header>
          <div className="grid-pg1">
            <div>
              <h3>
                <strong>
                  <ET
                    isPageTitle
                    titleKey="inspection"
                    reduxKey="inspection.title"
                    ml="50"
                  >
                    {inspection.title}
                  </ET>
                </strong>
              </h3>
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>
      <div className="doc-body template-body">
        <ET reduxKey="inspection.text" ml="26000" innerHtml={true}>
          {inspection.text}
        </ET>
      </div>
    </DocumentView>
  );
}

import React from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "trumbowyg/dist/ui/trumbowyg.min.css";
import "trumbowyg/dist/trumbowyg.min.js";
import "trumbowyg/dist/plugins/table/trumbowyg.table.min.js";
import "trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css";
import ReactDOM from "react-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { createStore } from "redux";
import App from "./App";
import axios from "./axios-defaults";
import "./index.css";
import ErrorBoundary from "./main/components/ErrorBoundry/ErrorBoundry";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Reducer from "./store";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENV,
  tracesSampleRate: 1.0,
});

if (process.env.NODE_ENV !== "development") {
  console.log("production");
  console.log = () => {};
}

const token = localStorage.getItem("access_token");
const customToken = localStorage.getItem("x-auth-token");
if (token) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("access_token")}`;
}
if (customToken) {
  /* axios.defaults.headers.common["X-Custom-Information"] =
    "Bearer " + customToken; */
}

const store = createStore(
  Reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

reportWebVitals();

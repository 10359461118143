/* eslint-disable default-case */
import produce from "immer";
import { formatReportData, setPropFromString } from "../../main/shared/helpers";
import * as Actions from "../actions";

const INITIAL_STATE = {};

const reportReducer = produce((state, { type, payload }) => {
  const {
    commentIndex,
    roomIndex,
    observationIndex,
    photoIndex,
    testIndex,
    imageIndex,
    protocolIndex,
    sourceIndex,
    destinationIndex,
    contentIndex,
    sampleIndex,
    extraPageData,
    extraPageIndex,
  } = payload ?? {};

  switch (type) {
    case Actions.SET_REPORT_DATA:
      Object.assign(state, payload);
      break;
    case Actions.SET_REPORT_LEFT_TITLE:
      const contentLength = state?.toc?.content?.length ?? 0;
      console.log("contentLength", contentLength);
      mainLoop: for (let i = 0; i < contentLength; i++) {
        const subContentLength = state.toc.content[i]?.sub_content?.length;
        console.log("subContentLength", subContentLength);
        for (let j = 0; j < subContentLength; j++) {
          if (
            state.toc.content[i]?.sub_content[j]?.report_key ===
            payload.titleKey
          ) {
            state.toc.content[i].sub_content[j].label = payload.data;
            break mainLoop;
          }
        }
      }
      break;
    case Actions.SET_REPORT:
      setPropFromString(state, payload.path, payload.data);
      break;

    case Actions.ADD_REPORT_OBSERVATION_COMMENTS:
      state.observations.data[roomIndex].observations[
        observationIndex
      ].comments = [
          ...state.observations.data[roomIndex].observations[observationIndex]
            .comments,
          payload.data,
        ];
      break;

    case Actions.SET_REPORT_OBSERVATION_COMMENTS:
      setPropFromString(
        state.observations.data[roomIndex].observations[observationIndex]
          .comments[commentIndex],
        payload.path,
        payload.data
      );
      break;

      case Actions.REMOVE_REPORT_OBSERVATION_COMMENTS:
        state.observations.data[roomIndex].observations[observationIndex].comments.splice(commentIndex, 1);
        break;

    case Actions.SET_REPORT_OBSERVATION:
      setPropFromString(
        state.observations.data[roomIndex].observations[observationIndex],
        payload.path,
        payload.data
      );
      break;
    case Actions.SET_REPORT_OBSERVATION_PHOTO:
      setPropFromString(
        state.observations.data[roomIndex].observations[observationIndex]
          .images[photoIndex],
        payload.path,
        payload.data
      );
      break;
    case Actions.SET_REPORT_OBSERVATION_TESTS:
      setPropFromString(
        state.observations.data[roomIndex].observations[observationIndex].tests[
        testIndex
        ],
        payload.path,
        payload.data
      );
      break;
    case Actions.SET_REPORT_OBSERVATION_SAMPLE_INFO:
      state.observations.data[roomIndex].observations[observationIndex].tests[
        testIndex
      ].sample_info[sampleIndex].pivot.value = payload.data;

      break;
    case Actions.SET_REPORT_OBSERVATION_TEST_PHOTO:
      setPropFromString(
        state.observations.data[roomIndex].observations[observationIndex].tests[
          testIndex
        ].images[imageIndex],
        payload.path,
        payload.data
      );
      break;
    case Actions.SET_REPORT_OBSERVATION_ROOM_NAME:
      setPropFromString(
        state.observations.data[roomIndex],
        payload.path,
        payload.data
      );
      break;
    case Actions.SET_REPORT_OBSERVATION_PROTOCOL:
      state.observations.data[roomIndex].protocol_text[protocolIndex] =
        payload.data;
      break;
    case Actions.REMOVE_REPORT_OBSERVATION_PROTOCOL:
      state.observations.data[roomIndex].protocol_text.splice(protocolIndex, 1);
      break;
    case Actions.SET_REPORT_OBSERVATION_PROTOCOL_SUBTITLE:
      state.observations.data[roomIndex].protocol_header_text = payload.data;
      break;
    case Actions.SET_REPORT_OBSERVATION_PROTOCOL_TITLE:
      state.observations.data[
        roomIndex
      ].protocol_header_observation_consideration = payload.data;
      break;
    case Actions.REORDER_REPORT_OBSERVATIONS:
      const [removedObservation] = state.observations.data[
        roomIndex
      ].observations.splice(sourceIndex, 1);
      state.observations.data[roomIndex].observations.splice(
        destinationIndex,
        0,
        removedObservation
      );
      break;
    case Actions.REORDER_REPORT_ROOMS:
      const [removedRoom] = state.observations.data.splice(sourceIndex, 1);
      state.observations.data.splice(destinationIndex, 0, removedRoom);
      break;
    case Actions.REORDER_REPORT_SUB_CONTENT:
      const [removedSubItem] = state.toc.content[
        contentIndex
      ].sub_content.splice(sourceIndex, 1);
      state.toc.content[contentIndex].sub_content.splice(
        destinationIndex,
        0,
        removedSubItem
      );
      formatReportData(state);
      break;
    case Actions.REORDER_REPORT_SECTIONS:
      const [removedSection] = state.toc.content.splice(sourceIndex, 1);
      state.toc.content.splice(destinationIndex, 0, removedSection);
      formatReportData(state);
      break;
    case Actions.ADD_REPORT_EXTRA_PAGE:
      if (Array.isArray(state.extra_pages)) {
        state.extra_pages.push(extraPageData);
      } else {
        state.extra_pages = [];
        state.extra_pages.push(extraPageData);
      }
      break;
    case Actions.SET_REPORT_EXTRA_PAGE:
      if (Array.isArray(state.extra_pages) && state.extra_pages.length > 0) {
        setPropFromString(
          state.extra_pages[extraPageIndex],
          payload.path,
          payload.data
        );
      }
      break;
  }
}, INITIAL_STATE);

export default reportReducer;

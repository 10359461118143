import axios from "../axios-defaults";

const loginRequest = (userCredetials) => {
  return axios.post("/oauth/token", userCredetials);
};

const refreshTokenRequest = (data) => {
  return axios.post("/oauth/token", data, { _retry: true });
};

const profileRequest = () => {
  return axios.get("/api/v1/account?additional-info=issubscribed");
};

const forgetPassword = (data) => {
  return axios.post("/api/v1/forgot-password/request", data);
};

const resetPassword = (data) => {
  return axios.post("/api/v1/forgot-password/reset", data);
};

const contentRequest = (param) => {
  return axios.get(`/api/v1/contents?type=${param}`);
};

const checkResetTokenRequest = (token) => {
  return axios.get(`/api/v1/forgot-password/reset?token=${token}`);
};

const logoutRequest = () => {
  return axios.post(`/api/v1/account/logout`);
};

const updateSubAdminProfile = (data) => {
  return axios.post("api/v1/account/subadmin", { ...data, _method: "PATCH" });
};

export {
  loginRequest,
  profileRequest,
  forgetPassword,
  resetPassword,
  refreshTokenRequest,
  contentRequest,
  checkResetTokenRequest,
  logoutRequest,
  updateSubAdminProfile,
};

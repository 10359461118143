import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  REORDER_REPORT_OBSERVATIONS,
  REORDER_REPORT_ROOMS,
  REORDER_REPORT_SUB_CONTENT,
  REORDER_REPORT_SECTIONS,
} from "../../../../store/actions";

export default function DocumentNavigator({ setScrollToRow }) {
  const toc = useSelector((state) => state.report?.toc?.content);

  return (
    <ul className="ul-ir">
      <MainList toc={toc} setScrollToRow={setScrollToRow} />
    </ul>
  );
}

function ObservationAccordion({
  roomIndex,
  setScrollToRow,
  selectKey,
  selectedSection,
  setSelectedSection,
}) {
  const room = useSelector(
    (state) => state.report.observations.data[roomIndex]
  );
  const allRooms = useSelector((state) => state.report.observations.data);
  const [page, setPage] = useState(4);
  const [selectedObs, setSelectedObs] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    let startPage = 4;
    for (let i = 0; i < allRooms.length; i++) {
      if (i === roomIndex) {
        setPage(startPage);
        break;
      } else {
        startPage +=
          allRooms[i].observations.reduce((a, c) => {
            const pages =
              (c.comments.length > 0 ? 1 : 0) +
              (c.tests.length > 0 ? 1 : 0) +
              (c.images.length > 0 ? 1 : 0);
            return a + pages;
          }, 0) + 1;
      }
    }
  }, [allRooms, roomIndex]);

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#E7ECE8" : "transparent",
    paddingLeft: "50px",
    borderRadius: "8px",
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    console.log({
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
      roomIndex,
    });
    dispatch({
      type: REORDER_REPORT_OBSERVATIONS,
      payload: {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
        roomIndex,
      },
    });

    console.log(result);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#CFE7EC" : "transparent",
    cursor: isDragging ? "grabbing" : "pointer",
    borderRadius: "8px",
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {room.observations.map((ob, i) => (
              <Draggable
                key={`room-${roomIndex}-ob-${i}`}
                draggableId={`room-${roomIndex}-ob-${i}`}
                index={i}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <li
                      {...provided.dragHandleProps}
                      className={
                        selectedObs === i && selectedSection === selectKey
                          ? "section-children-active section-children one-line-ellipsis"
                          : "section-children one-line-ellipsis"
                      }
                      onClick={() => {
                        const pages =
                          (ob?.comments?.length > 0 ? 1 : 0) +
                          (ob?.tests?.length > 0 ? 1 : 0) +
                          (ob?.images?.length > 0 ? 1 : 0);
                        setSelectedSection(selectKey);
                        setSelectedObs(i);
                        setScrollToRow(page + pages * i);
                      }}
                    >
                      <span>
                        #{ob.code.toUpperCase()} - {ob.location}
                      </span>
                    </li>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function RoomsList({ rooms, ObservationAccordionProps }) {
  const [isOpen, setIsOpen] = useState(-1);
  const dispatch = useDispatch();

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#E7ECE8" : "transparent",
    borderRadius: "8px",
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    console.log({
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });
    dispatch({
      type: REORDER_REPORT_ROOMS,
      payload: {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    });

    console.log(result);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#CFE7EC" : "transparent",
    cursor: isDragging ? "grabbing" : "auto",
    borderRadius: "8px",
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable2">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {rooms?.map((r, j) => (
              <Draggable key={`room-${j}`} draggableId={`room-${j}`} index={j}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <li
                      {...provided.dragHandleProps}
                      className={"section-children one-line-ellipsis"}
                      onClick={() => setIsOpen((s) => (s === j ? -1 : j))}
                    >
                      <FontAwesomeIcon
                        icon={faGripLines}
                        className="mx-2 cursor-move"
                      />
                      <span style={{ paddingLeft: "20px" }}>{r.room_name}</span>
                    </li>
                    {isOpen === j && (
                      <ObservationAccordion
                        {...ObservationAccordionProps}
                        selectKey={
                          ObservationAccordionProps.selectKeyI + "" + j
                        }
                        roomIndex={j}
                      />
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function SubContentList(props) {
  const {
    subContent,
    selectedSection,
    setScrollToRow,
    setSelectedSection,
    sectionI,
  } = props;
  const dispatch = useDispatch();
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#E7ECE8" : "transparent",
    borderRadius: "8px",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#CFE7EC" : "transparent",
    cursor: isDragging ? "grabbing" : "auto",
    borderRadius: "8px",
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    dispatch({
      type: REORDER_REPORT_SUB_CONTENT,
      payload: {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
        contentIndex: sectionI,
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable2">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {subContent?.map((content, j) => (
              <Draggable
                key={`subsection-${j}`}
                draggableId={`subsection-${j}`}
                index={j}
                isDragDisabled={props.draggable === false}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <li
                      id={content.page}
                      className={
                        selectedSection === sectionI + "" + j
                          ? "section-children-active section-children one-line-ellipsis"
                          : "section-children one-line-ellipsis"
                      }
                      onClick={() => {
                        setScrollToRow(content.page);
                        setSelectedSection(sectionI + "" + j);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faGripLines}
                        className="mx-2 cursor-move"
                      />
                      <span style={{ paddingLeft: "20px" }}>
                        {content.label}
                      </span>
                    </li>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function MainList(props) {
  const { toc, setScrollToRow } = props;
  const [selectedSection, setSelectedSection] = useState("00");
  const dispatch = useDispatch();
  const observationRooms = useSelector(
    (state) => state.report?.observations?.data
  );
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#E7ECE8" : "transparent",
    borderRadius: "8px",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#fff" : "transparent",
    cursor: isDragging ? "grabbing" : "auto",
    borderRadius: "8px",
    border: "1px solid #fff",
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    dispatch({
      type: REORDER_REPORT_SECTIONS,
      payload: {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <li className="section-accordion" style={{ cursor: "auto" }}>
        <FontAwesomeIcon icon={faGripLines} className="mx-2 cursor-move" />
        <span className="one-line-ellipsis">{toc[0].title}</span>
      </li>
      <SubContentList
        draggable={false}
        subContent={toc[0].sub_content}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        setScrollToRow={setScrollToRow}
        sectionI={0}
      />
      <Droppable droppableId="droppable3">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {toc?.map((section, i) =>
              i === 0 ? null : (
                <Draggable
                  key={`main-list-${i}`}
                  draggableId={`main-list-${i}`}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <li className="section-accordion">
                        <FontAwesomeIcon
                          icon={faGripLines}
                          className="mx-2 cursor-move"
                        />
                        <span className="one-line-ellipsis">
                          {section.title}
                        </span>
                      </li>
                      {section?.report_key === "observations" ? (
                        <RoomsList
                          rooms={observationRooms}
                          ObservationAccordionProps={{
                            setScrollToRow,
                            setSelectedSection,
                            selectedSection,
                            selectKeyI: i,
                          }}
                        />
                      ) : (
                        <SubContentList
                          subContent={section.sub_content}
                          selectedSection={selectedSection}
                          setSelectedSection={setSelectedSection}
                          setScrollToRow={setScrollToRow}
                          sectionI={i}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              )
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

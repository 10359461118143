export const SET_REPORT_DATA =
  "[INVESTIGATION REPORT] SET REPORT DATA FROM API";

export const SET_REPORT = "[INVESTIGATION REPORT] SET REPORT";

export const SET_REPORT_OBSERVATION =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION";

export const SET_REPORT_OBSERVATION_ROOM_NAME =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION ROOM NAME";

export const SET_REPORT_OBSERVATION_PROTOCOL =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION PROTOCOL";

export const REMOVE_REPORT_OBSERVATION_PROTOCOL =
  "[INVESTIGATION REPORT] REMOVE REPORT OBSERVATION PROTOCOL";

export const SET_REPORT_OBSERVATION_COMMENTS =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION COMMENTS";

export const ADD_REPORT_OBSERVATION_COMMENTS =
  "[INVESTIGATION REPORT] ADD REPORT OBSERVATION COMMENTS";

export const REMOVE_REPORT_OBSERVATION_COMMENTS = 
"[INVESTIGATION REPORT] REMOVE REPORT OBSERVATION COMMENTS";

export const SET_REPORT_OBSERVATION_PHOTO =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION PHOTO";

export const SET_REPORT_OBSERVATION_TEST_PHOTO =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION TEST PHOTO";

export const SET_REPORT_OBSERVATION_TESTS =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION TESTS";

export const SET_REPORT_OBSERVATION_SAMPLE_INFO =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION SAMPLE INFO";

export const REORDER_REPORT_OBSERVATIONS =
  "[INVESTIGATION REPORT] SWAP OBSERVATIONS";

export const REORDER_REPORT_ROOMS = "[INVESTIGATION REPORT] SWAP ROOMS";

export const REORDER_REPORT_SUB_CONTENT =
  "[INVESTIGATION REPORT] SWAP TOC SUB CONTENT";
export const REORDER_REPORT_SECTIONS =
  "[INVESTIGATION REPORT] SWAP REPORT SECTIONS";

export const SET_REPORT_OBSERVATION_PROTOCOL_SUBTITLE =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION PROTOCOL SUB TITLE";

export const SET_REPORT_OBSERVATION_PROTOCOL_TITLE =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION PROTOCOL TITLE";

export const ADD_REPORT_EXTRA_PAGE =
  "[INVESTIGATION REPORT] ADD REPORT OBSERVATION EXTRA PAGE";

export const SET_REPORT_EXTRA_PAGE =
  "[INVESTIGATION REPORT] SET REPORT OBSERVATION EXTRA PAGE";

export const SET_REPORT_LEFT_TITLE =
  "[INVESTIGATION REPORT] SET REPORT LEFT TITLE";

import React from "react";
import { useSelector } from "react-redux";
import ET from "./EditableText";

export default function ObservationPageTitle() {
  const title = useSelector((state) => state.report.observations.title);

  return (
    <h3>
      <strong>
        <ET reduxKey="observations.title" ml="32">
          {title}
        </ET>
      </strong>
    </h3>
  );
}
